import axios from 'axios'
import { MessageBox } from 'element-ui'
import router from '@/router'
import { removeCookie } from '@/utils/cookie'

const service = axios.create({
    baseURL: "/api/v1",
    headers: {
        'Cache-Control': 'no-cache, no-store',
        'Pragma': 'no-cache'
    },
    timeout: 3 * 60 * 1000 
});

//添加请求拦截器
service.interceptors.request.use(
    config => {
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

let messageInstance = null;
const resetMessage = (params) => {
    if (messageInstance) {
        messageInstance.close()
    }
    messageInstance = MessageBox({
        title: '提示',
        type: 'error',
        message: params.message || '确认',
        confirmButtonText: params.confirmButtonText,
        callback: () => {
            if(params.callback){
                params.callback()
            }
        }
    })
};


//添加响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code == 401) {  //登录超时
            resetMessage({
                message: res.msg,
                confirmButtonText:'重新登录',
                callback: ()=> {
                    removeCookie()
                    router.push('/login');
                }
            })
            return Promise.reject(new Error(res.msg))
        } else if(res.code === 0){
            return res
        } else {
            MessageBox.alert(res.msg, '提示', {type:'error'});
            return Promise.reject(new Error(res.msg))
        }
    },
    error => {
        resetMessage({
            message: error.msg
        })
        return Promise.reject(error)
    }
)

export default service