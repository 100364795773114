<template>
    <div class="page-aside">
        <el-button class="collapse-btn" :icon="isCollapse ? 'el-icon-s-unfold':'el-icon-s-fold'" @click="toggleNav"></el-button>
        <el-scrollbar wrap-class="scrollbar-wrapper">
            <el-menu
                :router="true"
                :default-active="activeMenu"
                :collapse="isCollapse"
                :unique-opened="false"
                :collapse-transition="false"
                mode="vertical"
            >
                <nav-item v-for="route in menus" :key="route.name" :item="route" :base-path="route.path" />
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import navItem from './Item'

export default {
    components: { navItem },
    computed: {
        ...mapState({
            menus: state => state.menus,
            isCollapse: state => state.isCollapse
        }),
        activeMenu() {
            const route = this.$route
            const { meta, name } = route
            let activeMenu = meta.activeMenu || name
            
            return activeMenu
        }
  },
  methods:{
      ...mapMutations(['changeCollapse']),

        toggleNav() {
            this.changeCollapse(!this.isCollapse)
        },
  }
}
</script>