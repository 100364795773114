import router from './router'
import store from './store'
import { getCookie } from './utils/cookie'

const routerList = ['/login', '/auth-redirect']

router.beforeEach(async(to, from, next) => {
    const isLogin = getCookie() || false

    if(!isLogin){
        if (to.name==='login' || to.name==='Page404') {
            next()
        } else {
            next('/login')
        }
    } else {
        let supplyStatus = store.state.user.status
        if(supplyStatus===-1){
            const info = await store.dispatch('user/isLogin')
            if(!info.is_login){
                next('/login')
            }
            supplyStatus = info.user.status

            next({ ...to, replace: true })
        }else if(supplyStatus===0){
            if(to.name==='AddInformation'){
                next()
            } else {
                next('/information/add')
            }
        } else if(supplyStatus===1 || supplyStatus===5 || supplyStatus===6){
            if(to.name==='EditInformation' || to.name ==='Information'){
                next()
            } else {
                next('/information/list')
            }
        } else {
            const toName = to.name
            if(toName==='EditInformation' || toName ==='Information'|| toName ==='AddInformation'){
                next('/onlinePrice')
            } else {
                next()
            }
        }
    }
});


// router.beforeEach(async(to, from, next) => {
//     const isLogin = store.state.user.isLogin

//     if(!isLogin){
//         if (to.name==='login') {
//             next()
//         } else {
//             next('/login')
//         }
//     } else {
//         let supplyStatus = store.state.user.status

//         if(supplyStatus===-1){
//             const info = await store.dispatch('user/isLogin')
//             supplyStatus = info.status
//         } else if(supplyStatus===0){
//             if(to.name==='AddInformation'){
//                 next()
//             } else {
//                 next('/information/add')
//             }
//         } else if(supplyStatus===1){
//             if(to.name==='EditInformation' || to.name ==='Information'){
//                 next()
//             } else {
//                 next('/information/list')
//             }
//         } else {
//             const toName = to.name
//             if(toName==='EditInformation' || toName ==='Information'|| toName ==='AddInformation'){
//                 next('/onlinePrice')
//             } else {
//                 next()
//             }
//         }
//     }
// });