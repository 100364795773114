<template>
    <div class="app-wrapper full-app-wrapper" :class="[isCollapse && 'app-wrapper_collapse']">
        <page-header :only="true" />
        <div class="page-main">
            <router-view />
        </div>
    </div>
</template>

<script>
import pageHeader from './components/pageHeader'
import { mapState } from 'vuex'

export default {
    name: 'LayoutsHeader',
    components: {
        pageHeader,
    },
    computed: {
        ...mapState({
            isCollapse: state => state.isCollapse
        }),
    }
}
</script>