import Vue from 'vue'
import Vuex from 'vuex'
import { navRouter } from '@/router'
import user from './modules/user'

Vue.use(Vuex)

function removeHidden(routes){
    if(routes){
        return routes.filter((item)=>{
            return !item.hidden
        }).map((item)=>{
            return {
                path: item.path,
                name: item.name,
                meta: item.meta,
                children: removeHidden(item.children)
            }
        })
    } else {
        return []
    }
}

export default new Vuex.Store({
    state: {
        menus: removeHidden(navRouter),
        isCollapse: false,
        isLogin: false,
        supplyStatus: -1
    },
    mutations: {
        changeCollapse(state, collapse){
            state.isCollapse = collapse
        },
        
    },
    modules: {
        user
    }
})
