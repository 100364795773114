import service from "@/utils/request";

/**是否登录 */
export const isLoginAuth = (formData) => { return service.post("/auth", formData)};

/**供应商登录 */
export const supplierLogin = (formData) => { return service.post("/login/index", formData)};

/**登出 */
export const logout = (formData) => { return service.post("/logout", formData)};


/* 供应商信息---ADD */
export const getAreaList = (formData) => { return service.get("/area/list", {params:formData})};

/* 供应商信息---ADD */
export const addSupplier = (formData) => { return service.post("/supply/register", formData)};

/* 供应商信息---ADD */
export const submitRegiste = (formData) => { return service.post("/supply/submit-register-review", formData)};

/**获取供应商基本信息（by-供应商id） */
export const getSupplierInfo = (formData) => { return service.post("/supply/supply-info", formData)};
/**供应商修改密码 */
export const supplierPWReset = (formData) => { return service.post("/supply/reset-password", formData)};
/**供应商信息维护 */
export const supplierInfoUpdate = (formData) => { return service.post("/supply/manage-supply", formData)};


/**合同列表 */
export const getContractList = (formData) => { return service.get("/contract/list", {params:formData})};

/**获取合同详情by_id */
export const getContractDetailInfo = (formData) => { return service.get("/contract/info", {params:formData})};

/**获取合同须知信息 */
export const getContractNoticeInfo = (formData) => { return service.get("/contract/notice/info", {params:formData})};


/**报价管理 ---列表*/
export const getPriceList = (formData) => { return service.post("/target-price/index", formData)};

/**报价管理 ---详情*/
export const getPriceDetail = (formData) => { return service.post("/target-price/detail", formData)};

/**报价管理 ---报价记录*/
export const getPriceRecord = (formData) => { return service.post("/target-price/record", formData)};

/**报价管理 ---计算总价*/
export const totalPrice = (formData) => { return service.post("/target-price/total-price", formData)};

/**报价管理 ---创建*/
export const createdPrice = (formData) => { return service.post("/target-price/create", formData)};

/**报价管理 ---提交*/
export const sumbitPrice = (formData) => { return service.post("/target-price/submit-review", formData)};



