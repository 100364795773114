<template>
    <header class="page-header">
        <div class="logo">
            <img src="@/assets/images/logo.png" />
            <span>科房集团供应商报价系统</span>
        </div>
        <div class="right-menu">
            <el-dropdown>
                <span class="username">
                    <span>{{decodeURIComponent($store.state.user.supplyName)}}</span>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item class="user-info">
                        <p>
                            <label><img src="@/assets/images/dropdown-user.png" /></label>
                            编码：{{$store.state.user.userInfo.supply_code}}
                        </p>
                        <p>
                            <label><img src="@/assets/images/dropdown-phone.png" /></label>
                            手机：{{$store.state.user.userInfo.supply_phone}}
                        </p>
                        <p>
                            <label><img src="@/assets/images/dropdown-email.png" /></label>
                            邮箱：{{$store.state.user.userInfo.supply_email}}
                        </p>
                        <p @click="open=true">
                            <label><img src="@/assets/images/dropdown-pwd.png" /></label>
                            修改密码
                        </p>
                        <p @click="logout()">
                            <label><img src="@/assets/images/dropdown-logout.png" /></label>
                            登出
                        </p>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>


        <dialog-reset-pwd 
            :open.sync="open" >
        </dialog-reset-pwd>
    </header>
</template>
<script>
import { mapState } from 'vuex'
import DialogResetPwd from '../../Information/RestPwd'
export default {
    props:{
        only: Boolean
    },
    computed: {
        ...mapState({
            isCollapse: state => state.isCollapse
        }),
    },
    components:{
        DialogResetPwd
    },
    data(){
        return {
            open: false
        }
    },
    methods:{
        updatePwd(){},
        async logout() {
            await this.$store.dispatch('user/logout')
            this.$router.push('/login')
        }
    }
}
</script>
<style lang="scss" scoped>
.el-dropdown-menu{
    padding: 20px 0;
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    box-shadow: 0px 9px 49px 0px rgba(42, 45, 46, 0.14);
    border-radius: 12px;
    .el-dropdown-menu__item{
        padding: 0;
    }

    .el-dropdown-menu__item:hover{
        background: none;
        color: #111747;
    }
    .user-info{
        p{
            font-size: 14px;
            color: #111747;
            line-height: 30px;
            margin-bottom: 5px;
            padding: 0 25px;
            margin-bottom: 10px;
            &:hover{
                background-color: #FFF4F4;
            }
        }
        label{
            width: 16px;
            height: 16px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 12px;
            text-align: center;
            img{
                object-fit: cover;
                vertical-align: top;
            }
        }
    }
}
</style>