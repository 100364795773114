<template>
    <div v-if="!item.hidden">
        <template v-if="!item.children || item.children.length==0">
            <el-menu-item :route="{name:item.name}" :index="item.name" class="no-childen">
                <span class="nav-icon" v-if="item.meta.icon"><img :src="imgSrc(item.meta.icon)" /></span>
                <span class="nav-icon-hover" v-if="item.meta.hoverIcon"><img :src="imgSrc(item.meta.hoverIcon)" /></span>
                <span slot="title" class="nav-name">{{item.meta.title}}</span>
            </el-menu-item>
        </template>
        <el-submenu v-else ref="subMenu" :index="item.name" popper-append-to-body class="has-child">
            <template slot="title">
                <span class="nav-icon" v-if="item.meta.icon"><img :src="imgSrc(item.meta.icon)" /></span>
                <span class="nav-icon-hover" v-if="item.meta.hoverIcon"><img :src="imgSrc(item.meta.hoverIcon)" /></span>
                <span slot="title" class="nav-name">{{item.meta.title}}</span>
            </template>
            <nav-item
                v-for="child in item.children"
                :key="child.name"
                :item="child"
            />
        </el-submenu>
    </div>
</template>
<script>
export default {
    name: 'navItem',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods:{
        imgSrc(url){
            return require('@/assets/images/' + url + '.png')
        }
    }
}
</script>