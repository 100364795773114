<template>
    <el-dialog title="重置密码" :visible="open" width="500px" @close="canel" append-to-body>
        <el-form ref="form" :model="form" :rules="rules" label-width="90px" label-position="right" autocomplete="off">
            <el-form-item label="旧密码" prop="old_pass">
                <el-input v-model="form.old_pass" type="password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="新密码"  prop="new_pass">
                <el-input v-model="form.new_pass" type="password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirm_pass">
                <el-input v-model="form.confirm_pass" type="password" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="canel">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="confirm">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { supplierPWReset } from "@/api";
export default {
    name:'DialogPurchase',
    props: {
        open: {
            type: Boolean,
            default: ()=> false
        },
    },
    data(){
        return {
            loading: false,
            form: {
                old_pass: '',
                new_pass: '',
                confirm_pass: ''
            },
            rules: {
                old_pass: { required: true, message: '请输入旧密码', trigger: 'blur' },
                new_pass: [
                    {required: true,message: '请输入密码',trigger: 'blur'}, 
                    { min: 8,max: 16,message: '密码长度在 8-16个字符'}, 
                    { trigger: 'blur',validator: (rule, value, callback) => {
                        let reg = /^(?![\da-z]+$)(?![\dA-Z]+$)(?![\d!#$%^&*]+$)(?![a-zA-Z]+$)(?![a-z!#$%^&*]+$)(?![A-Z!#$%^&*]+$)[\da-zA-z!#$%^&*]{8,16}$/;
                        if (!reg.test(value)) {
                            callback(new Error('密码必须由大写字母、小写字母、特殊符号、数字中的三类组成!'))
                        } else{
                            if (this.form.confirm_pass !== '') {
                                this.$refs.form.validateField('confirm_pass');
                            }
                            callback()
                        }
                    } }
                ],
                confirm_pass: [{ required: true, trigger: 'blur', validator: (rule, value, callback) => {
                    if (value === '') {
                        callback(new Error('请再次输入密码'));
                    } else if (value !== this.form.new_pass) {
                        callback(new Error('两次输入密码不一致!'));
                    } else {
                        callback();
                    }
                }}],
            }
        }
    },
    methods:{
        canel(){
            this.form = {};
            this.$refs['form'].resetFields();
            this.$emit('update:open', false);
        },
        confirm(){
            this.$refs.form.validate((valid) => {
                if(valid){
                    this.$confirm('重置密码, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        const params = {
                            old_pass: this.form.old_pass,
                            new_pass: this.form.new_pass
                        }
                        this.loading = true
                        supplierPWReset(params).then((res)=>{
                            this.$alert('重置密码成功，请重新登录', '提示', {
                                type:'success',
                                callback: action =>{
                                    this.$router.push('/login')
                                }
                            });
                            this.$store.dispatch('user/logout')
                        }).finally(()=>{
                            this.loading = false
                        })
                    }).catch(() => {
                        // console.log('取消重置密码')   
                    });
                }
            });
            
        }
    }
}
</script>