import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Layout from '@/views/Layouts/Index'
import LayoutsHeader from '@/views/Layouts/Header'
export const navRouter = [
    {
        path: 'backstageManage',
        name: 'backstageManage',
        redirect: '/onlinePrice',
        hidden: true,
        meta: { title: '在线报价'},
        component: {render: (e)=> e("router-view")},
        children: [
            {
                path: '/onlinePrice/detail/:id',
                name: 'PriceDtail',
                component: () => import('@/views/OnlinePricing/Detail'),
                hidden: true,
                meta: { title: '报价详情', activeMenu:'priceList'}
            }
        ]
    },
    {
        path: '/onlinePrice',
        name: 'priceList',
        component: () => import('@/views/OnlinePricing/List'),
        meta: { title: '在线报价', icon:'nav-supply',hoverIcon:'nav-supply-hover'}
    },
    {
        path: 'contractManage',
        name: 'contractManage',
        redirect: '/contractmanage/list',
        hidden: true,
        meta: { title: '合同打印'},
        component: {render: (e)=> e("router-view")},
        children: [
            {
                path: '/contractmanage/detail/:id',
                name: 'contractDetail',
                component: () => import('@/views/ContractManage/Detail'),
                hidden: true,
                meta: { title: '合同详情', activeMenu:'contractList'}
            }
        ]
    },
    {
        path: '/contractmanage/list',
        name: 'contractList',
        component: () => import('@/views/ContractManage/List'),
        meta: { title: '合同打印', icon:'nav-contract',hoverIcon:'nav-contract-hover'}
    },{
        path: '/information/detail',
        name: 'detailInfo',
        component: () => import('@/views/Information/Edit'),
        meta: { title: '信息维护', icon:'nav-message',hoverIcon:'nav-message-hover'}
    }
]

const router = new Router({
    routes:[
        {
            path: '/404',
            name: 'Page404',
            component: () => import('@/views/ErrorPage/404')
        },
        {
            path: '/login',// 登录
            name: 'login',
            component: () => import('@/views/Login/new'),
            meta: { title: '登录'}
        }, 
        {
            path: '/information',
            name:'InformationMan',
            component: LayoutsHeader,
            children: [
                {
                    path: 'add', 
                    name: 'AddInformation',
                    component: () => import('@/views/Information/Add'),
                    meta: { title: '注册信息'}
                },{
                    path: 'edit', 
                    name: 'EditInformation',
                    component: () => import('@/views/Information/Edit'),
                    meta: { title: '信息修改'}
                },{
                    path: 'list', 
                    name: 'Information',
                    component: () => import('@/views/Information/List'),
                    meta: { title: '信息列表'}
                },
            ]
        },
        
        {
            path: '/',
            component: Layout,
            children: navRouter
        },{
            path: "*",
            redirect: "/404"
        }
    ]
})

export default router