import Cookies from 'js-cookie'

const cookieKey = 'kefangSupply'

export function getCookie() {
  return Cookies.get(cookieKey)
}

export function setCookie(token) {
  return Cookies.set(cookieKey, token)
}

export function removeCookie() {
  return Cookies.remove(cookieKey)
}
