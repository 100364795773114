import { removeCookie, setCookie, getCookie } from '@/utils/cookie'
import { isLoginAuth, logout } from '@/api/index'


function setUser(commit, data){
    commit('SET_NAME', data.supply_company)
    commit('SET_CODE', data.supply_code)
    commit('SET_STATUS', data.status)
    commit('SET_INFO', data)
}

const state = {
    supplyName: '',
    userInfo: {},
    supplyCode: '',
    status: -1,
    isLogin: getCookie()
}

const mutations = {
    SET_NAME(state, name) {
        state.supplyName = encodeURIComponent(name)
    },
    SET_CODE(state, code){
        state.supplyCode = parseInt(code)
    },
    SET_STATUS(state, code){
        state.status = parseInt(code)
    },
    SET_INFO(state, obj){
        state.userInfo = obj
    }
}
const getters = {}

const actions = {
    setInfo({ commit }, data) {
        return new Promise((resolve, reject) => {
            setUser(commit,data)
        })
    },
    isLogin({ commit, state }) {
        return new Promise((resolve, reject) => {
            isLoginAuth().then(res => {
                const { data } = res
                if(data.is_login){
                    setCookie(true)
                    setUser(commit,data.user)
                } else {
                    removeCookie()
                    setUser(commit,{})
                }
                resolve(data)
            }).catch(error => {
                console.log(commit, state)
                reject(error)
            })
        })
    },
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            logout().then(() => {
                const params = {
                    status:-1
                }
                setUser(commit,params)
                removeCookie()

                resolve()
            }).catch(error => {
                console.log(commit, state)
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}