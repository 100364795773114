<template>
    <div class="app-wrapper" :class="[isCollapse && 'app-wrapper_collapse']">
        <page-header />
        <page-aside />
        <div class="page-main">
            <breadcrumb class="breadcrumb" />
            <router-view class="page" />
        </div>
    </div>
</template>

<script>
import pageHeader from './components/pageHeader'
import pageAside from './components/pageAside/Index'
import Breadcrumb from './components/breadcrumb'
import { mapState } from 'vuex'

export default {
    name: 'Layouts',
    components: {
        pageHeader,
        pageAside,
        Breadcrumb
    },
    computed: {
        ...mapState({
            isCollapse: state => state.isCollapse
        }),
    }
}
</script>